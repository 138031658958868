import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBiGxtqO2osGjNNB7-A2Dw6_kdYTGUeUXU",
    authDomain: "weaposy.firebaseapp.com",
    databaseURL: "https://weaposy-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "weaposy",
    storageBucket: "weaposy.appspot.com",
    messagingSenderId: "780936679265",
    appId: "1:780936679265:web:3c5243abd9fa10ddc53f20",
    measurementId: "G-3NZ1WT3XQK"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };