import React from "react";
import { auth } from "./Firebase";
import { signInWithPopup, OAuthProvider } from "firebase/auth";

const AppleSignIn = () => {
  const handleSignIn = async () => {
    const provider = new OAuthProvider("apple.com");

    try {
      const result = await signInWithPopup(auth, provider);
      console.log("User Info: ", result.user);
    } catch (error) {
      console.error("Error Signing In: ", error);
    }
  };

  return <button onClick={handleSignIn}>Sign in with Apple</button>;
};

export default AppleSignIn;
