import React, { useState } from "react";
import firebase from "firebase/compat/app";
import "../App.css";
import { v4 as uuidv4 } from "uuid";
import { deleteUser, updateEmail, sendEmailVerification } from "firebase/auth";

const Authenticated = (props) => {
  const [deleteAccount, setDeleteAccount] = useState(false);

  const applyDeleteAccount = () => {
    if (props.accounts != null) {
      var formData = new FormData();
      var dCommand = [
        "DELETE FROM characters WHERE userid = '" + props.user.uid + "'", // 0
        "DELETE FROM items WHERE userid = '" + props.user.uid + "'", // 1
        "DELETE FROM friends WHERE charname = '" + props.accounts.charname + "' OR friendname = '" + props.accounts.charname + "'", // 2
        "DELETE FROM status WHERE charname = '" + props.accounts.charname + "'", // 3
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ];
      var sCommand = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ];
      var rVariable = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ];
      var lCommand = "";

      for (var j = 0; j < 20; j++) {
        formData.append("sqldeletecommand" + j, dCommand[j]);
        formData.append("sqlsavecommand" + j, sCommand[j]);
        formData.append("rt" + j, rVariable[j]);
      }
      formData.append("sqlloadcommand", lCommand);
      fetch("https://www.weapon-story.com/weaposy/SQL_Controller.php", {
        method: "POST",
        mode: "cors",
        body: formData,
      })
        .then((res) => {
          //return res.text();
        })
        .then((data) => {
          //console.log(data);
        })
        .catch((error) => {
          //console.log('mute false error');
        });
    }

    deleteUser(firebase.auth().currentUser).then(() => {
      props.doneProcess("Delete");
      setDeleteAccount(false);
    });
  };

  if (props.ready) {
    return (
      <div>
        <div className="character-container">
          <table className="character-table">
            <thead>
              <tr className="character-intable" key={uuidv4()}>
                <th className="character-intable">Email</th>
                {props.accounts != null ?
                  <th className="character-intable">{props.accounts.email}</th> :
                  <th className="character-intable">-</th>}
              </tr>
            </thead>
            <tbody>
              {props.accounts != null && props.accounts.charname != "no" ? (
                <>
                  <tr className="character-intable" key={uuidv4()}>
                    <td className="character-intable">Character Name</td>
                    <td className="character-intable">{props.accounts.charname}</td>
                  </tr>
                  <tr className="character-intable" key={uuidv4()}>
                    <td className="character-intable">Tier</td>
                    <td className="character-intable">{props.accounts.tier}</td>
                  </tr>
                  <tr className="character-intable" key={uuidv4()}>
                    <td className="character-intable">Gold</td>
                    <td className="character-intable">{props.accounts.gold}</td>
                  </tr>
                  <tr className="character-intable" key={uuidv4()}>
                    <td className="character-intable">Cash</td>
                    <td className="character-intable">{props.accounts.cash}</td>
                  </tr>
                  <tr className="character-intable" key={uuidv4()}>
                    <td className="character-intable">Fire</td>
                    <td className="character-intable">{props.accounts.fire}</td>
                  </tr>
                  <tr className="character-intable" key={uuidv4()}>
                    <td className="character-intable">Water</td>
                    <td className="character-intable">{props.accounts.water}</td>
                  </tr>
                  <tr className="character-intable" key={uuidv4()}>
                    <td className="character-intable">Wind</td>
                    <td className="character-intable">{props.accounts.wind}</td>
                  </tr>
                  <tr className="character-intable" key={uuidv4()}>
                    <td className="character-intable">Earth</td>
                    <td className="character-intable">{props.accounts.earth}</td>
                  </tr>
                  <tr className="character-intable" key={uuidv4()}>
                    <td className="character-intable">Card Slot</td>
                    <td className="character-intable">{props.accounts.cardslot}</td>
                  </tr>
                  <tr className="character-intable" key={uuidv4()}>
                    <td className="character-intable">Penalty</td>
                    <td className="character-intable">{props.accounts.penalty}</td>
                  </tr>
                  <tr className="character-intable" key={uuidv4()}>
                    <td className="character-intable">Leave Battle</td>
                    <td className="character-intable">{props.accounts.leavebattle}</td>
                  </tr>
                </>
              ) : (
                <tr className="character-intable" key={uuidv4()}>
                  <td className="character-intable">No data</td>
                  <td className="character-intable">-</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {!deleteAccount ? (
          <div className="button-container">
            <button
              className="button-normal"
              onClick={() => firebase.auth().signOut()}
            >
              Sign Out
            </button>
            <button
              className="button-delete"
              onClick={() => setDeleteAccount(true)}
            >
              Delete Account
            </button>
          </div>
        ) : (
          ""
        )}
        {deleteAccount ? (
          <div className="form-container">
            <div className="button-container">
              <div className="alert-text">
                This function cannot be undo (All character data will removed)
              </div>
              <button
                className="button-delete"
                onClick={() => applyDeleteAccount()}
              >
                Delete Account
              </button>
              <button
                className="button-normal"
                onClick={() => setDeleteAccount(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
};

export default Authenticated;